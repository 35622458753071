<template>
  <div>
    <v-icon color="blue"
            small class="mr-1"
            @click="onDialogViewTransaction"
            :disabled="userp.role_id=='61bf5eb0c73d7eea8634fbf4' || !userp.enable  ? true : false"
    >mdi-file-document 
    </v-icon>
    |

    <v-icon color="green"
            small class="mr-1"
            @click="onDialogDeposit"
             :disabled="userp.role_id=='61bf5eb0c73d7eea8634fbf4' || !userp.enable ? true : false "
    >Dep
    </v-icon>
    <v-icon color="white"
            small class="mr-1"
            @click="onDialogDeposit"
             :disabled="userp.role_id=='61bf5eb0c73d7eea8634fbf4' || !userp.enable  ? true : false"
    >&#x7c;
    </v-icon>
    <v-icon color="red"
            small
            @click="onDialogWithdraw"
             :disabled="userp.role_id=='61bf5eb0c73d7eea8634fbf4' || !userp.enable  ? true : false"
    > With
    </v-icon>
    <dialog-deposit
        :dialog-withdraw ="dialogDeposit"
        :darkmode="darkmode"
        :dialog="dialogDeposit"
        :loading="loading"
        :user="item"
        :user_balance="user_balance"
        @submit="onDeposit"
        @currentUser="updatedDeposit = $event"
        v-on:onCloseDialog="closeDialogDeposit"
    />
    <dialog-withdraw
        :dialog-withdraw ="dialogWithdraw"
        :darkmode="darkmode"
        :dialog="dialogWithdraw"
        :loading="loading"
        :user="item"
        :user_balance="user_balance"
        @submit="onWithdraw"
        @currentUser="updatedWithdaw = $event"
        v-on:onCloseDialog="closeDialogWithdraw"
    />
    <dialog-view-transaction
        v-if="dialogViewTransaction"
        :dialog-withdraw ="dialogViewTransaction"
        :darkmode="darkmode"
        :dialog="dialogViewTransaction"
        :selectedUser="data.selectedUser"
        v-on:onCloseDialog="closeDialogViewTransaction"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DialogDeposit from "../DialogDeposit";
import DialogWithdraw from "../DialogWithdraw";
import DialogViewTransaction from "../DialogViewTransaction";

export default {
  components: {DialogWithdraw,DialogDeposit,DialogViewTransaction},
  props: ['darkmode', "item", "userp", "user"],
  data() {
    return {
      updatedDeposit: null,
      updatedWithdaw: null,
      data: {
        descending: true,
        sortBy: '_id',
        page: 1,
        rowsPerPage: 10,
        fields: ["user", "date", "amount", "old_balance", "new_balance"],
        search: '',
        selectedUser:null
    },
      loading: false,
      dialogWithdraw: false,
      dialogDeposit: false,
      dialogViewTransaction: false,
      user_balance: 0,
    };
  },
   watch: {
    userp: function() {},
    user: function() {}
  },
  computed: {
    ...mapGetters("$_user", ["getCurrentUser"]),
  },
  methods: {
    cancelDelete() {
      this.dialogDelete = false
    },
    onDialogViewTransaction() {
      this.data.selectedUser = this.item._id
      this.dialogViewTransaction = true
    },
    closeDialogViewTransaction() {
      this.dialogViewTransaction = false
    },
    async onDialogDeposit() {
      if(this.item.role_id == "61829df5e64b1b1768832700" || this.item.role_id == "61829dfee64b1b1768832709"){
        let data =  {
          user_id: this.item._id
        }
        await this.$request
                    .post({
                    url: "tranc-player/checkDuplicate",
                    data: data,
                    })
                    .then((res) => {
                    if (res.data.code) {
                        this.user_balance = res.data.data.balance;
                    }
                    });
      }
      this.dialogDeposit = true
    },
    async closeDialogDeposit() {
      this.user = {
        user_id: null,
        amount: null,
      },
      await this.fetchUser();
      this.dialogDeposit = false;
      this.$emit('refresh');
    },
    async onDeposit(request) {
      this.loading = true;
      if (this.item._id) {
        await this.deposit({vm: this, data: request})
        await this.fetchUser();
        this.dialogDeposit = false
        this.$emit('refresh');
      }
      this.loading = false
    },
    async onDialogWithdraw() {
     
      if(this.item.role_id == "61829df5e64b1b1768832700" || this.item.role_id == "61829dfee64b1b1768832709"){
        let data =  {
          user_id: this.item._id
        }
        await this.$request
                    .post({
                    url: "tranc-player/checkDuplicate",
                    data: data,
                    })
                    .then((res) => {
                    if (res.data.code) {
                        this.user_balance = res.data.data.balance;
                    }
                    });
      }
      this.dialogWithdraw = true;
    },
    async closeDialogWithdraw() {
      this.user = {
        user_id: null,
        amount: null,
        amount_r: null,
        amount_b: null,
        amount_d: null,
      },
      await this.fetchUser();
      this.dialogWithdraw = false;
      this.$emit('refresh');
    },
     async onWithdraw(request) {
      this.loading = true;
      if (this.item._id) {
        await this.withDraw({vm: this, data: request})
        this.dialogWithdraw = false
        await this.fetchUser();
        this.$emit('refresh');
        
      }
      this.loading = false
    },

    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_user", ["fetchPopUp", "fetchCurrentUser", "deleteUser", "fetchListUsers", "changePassword", "withDraw","deposit","fetchListUsers","updateUser"]),
    ...mapActions("$_modules", ["fetchUser"]),
  },
};
</script>
