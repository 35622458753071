<template>
  <div v-if="dialog && user">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition" max-width="1000px">
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            ប្រវត្តិនៃការដក/ដាក់ប្រាក់ 
          </v-toolbar-title>
        </v-toolbar>
        <Loading :visible="loading"/>
        <v-card-text>
          <v-container>
            <v-tabs
                v-model="tab"
                background-color="transparent"
                grow
            >
                <v-tab
                v-for="(item, index) in items"
                :key="item"
                @click="changeTab(index)"
                >
                {{ item }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab"  >
                <v-tab-item
                v-for="item in items"
                :key="item"
                >
                <v-card
                    flat
                > <v-card-text>
                   <v-data-table
                            :footer-props="footerProps" 
                            :items-per-page.sync="data.rowsPerPage"
                            :page.sync="data.page"
                            @update:page="getData"
                            @update:items-per-page="getData"
                            :server-items-length="item === 'Withdraw History' ? withdrawlist.objectCount : depositlist.objectCount"
                        :headers="headers"
                        :items="item === 'Withdraw History' ? withdrawlist.object : depositlist.object"
                        class="elevation-1"
                        mobile-breakpoint=0
                        >
                      <template v-slot:[`item.amount`]="{ item }">
                       <span  v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && (item.currency_type===1 || item.currency_type===3) " >
                        <span :style="currentTab == 0 ? 'color: red':'color: green'">
                        {{ currencyFormatUsd(item.amount) + "$"}}
                        </span>
                      </span>
                      <span  v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===2" >
                        <span :style="currentTab == 0 ? 'color: red':'color: green'">
                        {{ currencyFormatKh(item.amount) + "៛"}}
                        </span>
                      </span>
                    </template>
                     <template v-slot:[`item.old_balance`]="{ item }">
                       <span  v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && (item.currency_type===1 || item.currency_type===3) " >
                        {{ currencyFormatUsd(item.old_balance) + "$"}}
                      </span>
                      <span  v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===2" >
                        {{ currencyFormatKh(item.old_balance) + "៛"}}
                      </span>
                    </template>
                     <template v-slot:[`item.new_balance`]="{ item }">
                       <span v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && (item.currency_type===1 || item.currency_type===3) " >
                        {{ currencyFormatUsd(item.new_balance) + "$"}}
                      </span>
                      <span v-if="item.role_id !=='612a3fcc1a1fcead8871e822' && item.currency_type===2" >
                        {{ currencyFormatKh(item.new_balance) + "៛"}}
                      </span>
                    </template>
                    </v-data-table>
                 
                </v-card-text>
                </v-card>
                </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import headers from "../_api/columnsHistory";
import {mapActions, mapGetters} from 'vuex';

export default {
  created() {
    this.data.selectedUser =  this.selectedUser;
    this.fetchHistoryWithdraw(this.data)
    this.fetchHistoryDeposit(this.data)
  },
data () {
    return {
      data: {
        descending: true,
        sortBy: '_id',
        page: 1,
        rowsPerPage: 10,
        fields: ["user", "date", "amount", "old_balance", "new_balance"],
        search: '',
        selectedUser:null
    },
    currentTab: 0,
      headers: headers,
      tab: null,
       footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
      items: [
        'Withdraw History', 'Deposit History',
      ],
    }
  },
  props: {
    selectedUser:{
      type: String,
      default : null
    },
    dialog: {
      type: Boolean,
      default: false
    },
    darkmode: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    item: {
      type: Object,
      default: null,
    },
    rules: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
    user: {
      type: Object,
      default: function () {
        return {
          _id: null,
          user_name: null,
        }
      }
    }
  },
  computed: {
    ...mapGetters("$_user", {
      withdrawlist: 'getViewTransaction',
      depositlist: 'getDepositTransaction',
    }),
  },
  methods: {
    getData(){
    this.fetchHistoryWithdraw(this.data)
    this.fetchHistoryDeposit(this.data)
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
    },
    changeTab(index){
      this.currentTab = index;
      console.log(this.currentTab);
      return this.currentTab;
    },
    ...mapActions("$_user", ["fetchHistoryWithdraw", "fetchHistoryDeposit"]),
  }
}
</script>
<style lang="scss" scoped>

</style>
