<template>
  <div v-if="dialog && user">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            សូមធ្វើការបញ្ចូលចំនួនទឹកប្រាក់ដែលអ្នកចង់ដក
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :disabled="loading"  text @click="validate">
              Withdraw 
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading"/>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation>
                
              <v-text-field
                  v-if="user.role_id=='612a3fcc1a1fcead8871e822'"
                  class="mt-5"
                  v-model="user.amount_d"
                  label="Amount USD"
                  :rules="rules.amount_d"
                  min= 1
                  type=number
              />
             
              <v-text-field
                  v-if="user.role_id=='612a3fcc1a1fcead8871e822'"
                  class="mt-5"
                  v-model="user.amount_r"
                  label="Amount KHMER"
                  :rules="rules.amount_r"
                  min= 1
                  type=number
              />
              <div v-else>
                <v-text-field
                  class="mt-5"
                  v-model="user_balance"
                  label="សមតុល្យចុងក្រោយ"
                  disabled="true"
                  type=number
                />
                <v-text-field
                  class="mt-5"
                  v-model.number="user.amount"
                  label="Amount"
                  :rules="rules.amount"
                  min= 1
                  type=number
                />
              </div>
              
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    darkmode: {
      type: Boolean
    },
    user_balance: {
      type: Number
    },
    loading: {
      type: Boolean
    },
    rules: {
      type: Object,
      default: function () {
        return {
          amount: [
            v => !!v || 'Input amount is required',
            v => /^-?[0-9]+$/.test(v) || 'input is must be integer'
          ],
          amount_b: [
            v => !!v || 'Input amount is required',
             v => /^-?[0-9]+$/.test(v) || 'input is must be integer'
          ],
          amount_d: [
            v => !!v || 'Input amount is required',
             v => /^-?[0-9]+$/.test(v) || 'input is must be integer'
          ],
          amount_r: [
            v => !!v || 'Input amount is required',
             v => /^-?[0-9]+$/.test(v) || 'input is must be integer'
          ]
        }
      }
    },
    isValidate: {
      type: Boolean,
      default: true
    },
    user: {
      type: Object,
      default: function () {
        return {
          user_id: null,
          amount: null,
          amount_r:null,
          amount_d:null,
          amount_b:null,
        }
      }
    }
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate
      },
      // setter
      set: function (newValue) {
        this.$emit('update:isValidate', newValue)
      }
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        if(this.user.role_id=='612a3fcc1a1fcead8871e822') this.user.amount= 0
        else {
          this.user.amount_r= 0
          this.user.amount_d= 0
          this.user.amount_b= 0
        }
        const data = { user_id: this.user._id, amount: parseInt(this.user.amount), amount_r: parseInt(this.user.amount_r), amount_b: parseInt(this.user.amount_b), amount_d: parseInt(this.user.amount_d)};
        // console.log(data)
        this.$emit('submit', data)
        this.$emit('currentUser', this.user)
      }
    }
  }
}

</script>
